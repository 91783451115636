.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;  
}

form {
  margin-top: 20px;
}

.centered-text {
  text-align: center;
}