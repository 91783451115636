.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .content {
    font-size: 18px;
    text-align: center;
  }
  