.video-container {
    text-align: center;
  }
  
  .video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio for responsive video */
    margin-top: 20px;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 600px) {
    .video-wrapper {
      padding-bottom: 75%; /* Adjust aspect ratio for mobile devices */
    }
  }
  