.container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column {
    flex: 1;
    margin: 10px;
  }
  
  .pos-machine-image {
    max-width: 100%;
    height: auto;
  }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 600px) {
    .column {
      flex-basis: 100%;
    }
  }
  