.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .content {
    text-align: center;
    padding: 20px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 20px;
  }
  
  .error-title {
    font-size: 24px;
    color: red;
    margin-bottom: 10px;
  }
  
  .error-description {
    font-size: 18px;
    color: red;
    margin-bottom: 20px;
  }
  